//*** Import react library
import React, { useEffect, useState } from "react";
import WebcamRenderer from "./WebcamRenderer";
/*import { NavMenu } from "../NavMenu";*/
import "../../Assets/Styles/robot.css";
import { ControlPanel } from "./ControlPanel";
import slow from "../../Assets/Images/Slow.png";
import medium from "../../Assets/Images/Medium.png";
import fast from "../../Assets/Images/Fast.png";
import videoCam from "../../Assets/Images/Video_R1.png";
import Joystick from "./JoystickController";
import mqtt from "mqtt/dist/mqtt";
/*import styles from "../../Assets/Styles/StatusInfo.css";*/
import DataTable from "react-data-table-component";
/*import BatteryGauge from 'react-battery-gauge';*/
import "../../Assets/Styles/tab.css";
import { RobotLocation } from "./RobotLocation";
import Axios from 'axios'
/*import Iframe from "react-iframe";*/
/*var i = 0;*/
/*let newdata;*/
const columns = [
   /* { name: "Plant ID", selector: (row) => row.plantID, sortable: true },*/
    { name: "Block", selector: (row) => row.blockName, sortable: true },
    { name: "Row", selector: (row) => row.rowName, sortable: true },
    {
        name: "Robot", selector: (row) => row.robotName, sortable: true
    },
    { name: "Speed", selector: (row) => row.speed, sortable: true },
    { name: "GC", selector: (row) => row.grassCutter, sortable: true },
    { name: "SP", selector: (row) => row.sprayer, sortable: true },
    //{ name: "SO", selector: (row) => row.sowing, sortable: true },
    //{ name: "HA", selector: (row) => row.harvesting, sortable: true },
    { name: "Command", selector: (row) => row.command, sortable: true },
    { name: "Command Status", selector: (row) => row.currentCommandStatus, sortable: true },
    //{ name: "CB", selector: (row) => row.controllerBatteryStatus, sortable: true },
    //{ name: "MB", selector: (row) => row.motorBatteryStatus, sortable: true },
    { name: "Date Time", selector: (row) => row.workingDate, sortable: true },
];
const customStyles = {
    table: {
        style: {
            border: "none",
            backgroundColor: "transparent",
            fontSize: "100%"
        },
    },
    header: {
        backgroundColor: "transparent",
    },
    rows: {
        style: {
            backgroundColor: "transparent",
        },
    },
    headRow: {
        style: {
            backgroundColor: "transparent",
            fontSize: "105%"
        },
    },
    pagination: {
        style: {
            backgroundColor: "transparent",
            fontWeight: "700",
        },
    },
    headCells: {
        style: {
            borderBottom: "none",
            color: "#505050",
            fontWeight: "700",
            fontSize: "120%"
        },
    },
};

//*** Use anyone MQTT Broker ***//
//const url = 'ws://broker.emqx.io:8083/mqtt'
//const url = 'ws://broker.mqttdashboard.com:8000/mqtt'
const url = 'wss://broker.hivemq.com:8884/mqtt'
//IPAddress = "https://www.youtube.com/embed/tgbNymZ7vqY"

///*** Global variables 
var PlantID;
var RobotID;
var UserID;
const MQTT_Subscribe_Topic = "zylv4u8fun1";
const MQTT_Receiver_Topic = "e1ypuwron21";
const ClienID = Math.floor(100000000000 + Math.random() * 900000000000);
var IPAddress;
var SubscribtionStatus = false;
var BroadcastingStatus = false;
var CameraCaptureStatus = false;
var Current_command_status = null;
var Current_motor_speed_status = null;
var Current_turn_status = null;
var Current_grass_cutter_status = null;
var Current_sprayer_status = null;
//var Current_sowing_status = null;
//var Current_haversting_status = null;
var Current_data = null;
//*** End Global variables ***//

///***
// * for Support url -https://www.emqx.com/en/blog/mqtt-js-tutorial
// * Browser
// * Using MQTT over WebSocket with ws and wss protocols
// * EMQX's ws connection default port is 8083, wss is 8084
// * Note that you need to add a path after the connection address, such as /mqtt
// */
//const url = 'ws://broker.emqx.io:8083/mqtt'
///***

// * Node.js
// * Using MQTT over TCP with mqtt and mqtts protocols
// * EMQX's mqtt connection default port is 1883, mqtts is 8883
// */

//** Create an MQTT mqtt_client instance

//*** Declare option parameters for MQTT protocol ***//
const options = {
    //Clean session
    //clean: true,
    keepalive: 1060,
    connectTimeout: 500000,
    //*Authentication
    clientId: `mqttjs_${Math.random().toString(16).substr(2, 8)}`,
    //userName: 'emqx_test',
    //password: 'emqx_test',
}

// *** Step-1 ***//
//*** Connect To MQTT Broker ***//
// Temp comment on 20230718
var mqtt_client = mqtt.connect(url, options)
mqtt_client = new mqtt.connect(url, options)
mqtt_client.on('connect', function (err) {
    if (err) {
      // Temp comment on 20230831 Create_broadcasting_command_message();
   }
    if (!err) {
      console.log("Connected To MQTT Broker")
   }
})

//*** If MQTT connection lost then continuously check broadcasting network between UI and Bhuhit Robot ***/
//*** SetInterval(check_mqtt_cloud_connectivity, 9000);
function check_mqtt_cloud_connectivity() {
    mqtt_client.on('connect', function (err) {
        if (err) {
            Create_broadcasting_command_message();
        }
        if (!err) {
            alert("Client Connected")
        }
    })
  }

//*** Create broadcasting frame message  old function***//
function Create_broadcasting_command_message() {
    var obj = new Object();
    obj.FID = RobotID;
    var Broadcasting_command_message = JSON.stringify(obj);
    Send_broadcasting_command_message_to_robot(Broadcasting_command_message);
}
//*** Create broadcasting frame message new function***//
export const Create_broadcast_frames = () => {
    var obj = new Object();
    obj.FID = RobotID;
    var Broadcasting_command_message = JSON.stringify(obj);
    Send_broadcasting_command_message_to_robot(Broadcasting_command_message);
};

//*** Step-2 ***//
//*** Send MQTT broadcasting message to robot ***//
function Send_broadcasting_command_message_to_robot(Broadcasting_command_msg) {
    try {
        //*** Check MQTT client is subscribed or not
        mqtt_client.subscribe(MQTT_Subscribe_Topic, function (err) {
            if (!err) {
                //*** Send MQTT Broadcasting Frame Message to Robot ***//
                mqtt_client.publish(MQTT_Subscribe_Topic, Broadcasting_command_msg)
                console.log("Step-1 (Broadcasting): Operator have sent Robot ID 'RBT000001' to Robot and have completed step 1/6.");
                BroadcastingStatus = true; // Set Global Variables BroadcastingStatus = true
            }
        })
    }
    catch (e) {
        console.log('Broadcasting Error')
    }
}

//*** Start button click used to subscribe to MQTT topic.
export const Client_subscribe_to_mqtt_topic = () => {
    var subscribe_topic;
    try {
       // temp comment on 20231223 mqtt_client = new mqtt.connect(url, options)
        //*** Subescribe to pblish topic 'zylv4u8fun' ***//
        mqtt_client.subscribe(MQTT_Subscribe_Topic, function (err) {
            if (!err) {
                console.log('Client subscribed to MQTT pblish Topic')
            }
            if (err) {
                console.log('Client not subscribed to MQTT pblish Topic')
            }
        })
        subscribe_topic = MQTT_Subscribe_Topic;
        /**** Subscribe to Message receiver topic ***/
        mqtt_client.subscribe(MQTT_Receiver_Topic, function (err) {
            if (!err) {
                console.log('Client subscribed to MQTT receiver topic')
            }
            if (err) {
                console.log('Client not subscribed to MQTT receiver topic')
            }
        })
        subscribe_topic = subscribe_topic + "," + MQTT_Subscribe_Topic;
        SubscribtionStatus = true;
    }
    catch (e) {
        SubscribtionStatus = false;
        console.log('Error to client suscription topic');
    }
};

//*** Receive messages from Bhuhit robots to MQTT and then MQTT to Bhuhit UI ***//
mqtt_client.on('message', function (topic, message) {
    //*** message is Buffer ***//
    //alert(message + "s");
    var obj = JSON.parse(message);
    // Temp comment on 20231223 RobotID = obj.FID; 
    //*** Check broadcasting message ***//
    if (obj.FID != null) {
        //*** If we got IP address and FID from robot then UI have connected to robot and we will update robot connected status in database
        if (obj.IP != null) {
            IPAddress = obj.IP;
           // alert(IPAddress);
            RobotID = obj.FID;
            // *** Update robot connected status in database.
            //const bcresponse = Axios("/RobotBroadcast/addbcs?pid=" + PlantID + "&uid=1&rid=" + RobotID + "&rcs=Connected");
            //main comment on 20240626 const bcresponse = Axios("/RobotBroadcast/addbcs?pid=" + PlantID + "&uid="+UserID +"&rid=" + RobotID + "&rcs=Connected");
        }
        //*** Assigne global variable to data of received frames.
        if (obj.Cmd != null) Current_command_status = obj.Cmd;
        if (obj.Spd != null) Current_motor_speed_status = obj.Spd;
        if (obj.Turn != null) Current_turn_status = obj.Turn;
        if (obj.GC != null) Current_grass_cutter_status = obj.GC;
        if (obj.SP != null) Current_sprayer_status = obj.SP;
       
        /*** Step-2 and Step-3 have executed. Operator have connected to Robot ***/
        /*** Step-3 (Connectivity to Robot): You connected to Robot and completed step 3 /6. ***/
        console.log("Step-2 (Broadcasting): Operator have received Robot ID " + RobotID + " from Robot and have completed step 2/6.");// message.payloading
        console.log("Step-3 (Connectivity to Robot): You are connected to Robot and have completed step 3 /6.");
        // *** Update command receving status in database using API
        //fetch("/PlantRobotOperation/addcmdstatus?pid=" + PlantID + "&uid=1&rid=" + RobotID + "&cmd=" + Current_command_status + "&tun=" + Current_turn_status + "&spd=" + Current_motor_speed_status + "&gc=" + Current_grass_cutter_status + "&sp=" + Current_sprayer_status + "&cb=0&mb=0&cid=1",
        //Temp comment 20240629 const addCmdStatusResponse = Axios("/PlantRobotOperation/addcmdstatus?pid=" + PlantID + "&uid=" + UserID +"&rid=" + RobotID + "&cmd=" + Current_command_status + "&tun=" + Current_turn_status + "&spd=" + Current_motor_speed_status + "&gc=" + Current_grass_cutter_status + "&sp=" + Current_sprayer_status + "&cb=0&mb=0&cid=1");
        //fetch("https://bhuhit.com/api/PlantRobotOperation/addcmdstatus?pid=" + PlantID + "&uid=1&rid=" + RobotID + "&cmd=" + Current_command_status + "&tun=" + Current_turn_status + "&spd=" + Current_motor_speed_status + "&gc=" + Current_grass_cutter_status + "&sp=" + Current_sprayer_status + "&cb=0&mb=0&cid=1",
        //    {
        //        mode: 'no-cors',
        //    }
        //).then(res => res.text())
        //    .then((data) => {
        //    })
        //    .catch((err) => {
        //        console.log(err.message);
        //        alert(err.message + " - Not fetching data from database.");
        //    });
    }
    console.log(message.toString() + " 1")
    //mqtt_client.end()// temp comment
})

//*** Control panel's buttons grasscutter, sprayer, sowing and haversting have value 0 & 1 ***//
//** If camera capture status is true and Robot connected status from UI is true then create MQTT frame to send command message
export const  Create_mqtt_command_message = async (ctrl, ctrl_current_status) => {
    //*** Set ctrl current value - 0 & 1 ***//
    if (ctrl === "Command") Current_command_status = ctrl_current_status;
    else if (ctrl === "Turn") Current_turn_status = ctrl_current_status;
    else if (ctrl === "Speed") Current_motor_speed_status = ctrl_current_status;
    else if (ctrl === "GrassCutter") Current_grass_cutter_status = ctrl_current_status;
    else if (ctrl === "Sprayer") Current_sprayer_status = ctrl_current_status;
    else if (ctrl === "StopOperation") mqtt_client.end();

    BroadcastingStatus = true;
    if (CameraCaptureStatus === true) {
        var obj = new Object();
        obj.FID = RobotID;
        obj.Cmd = Current_command_status;
        obj.Spd = Current_motor_speed_status;
        obj.Turn = Current_turn_status;
        obj.GC = Current_grass_cutter_status;
        obj.SP = Current_sprayer_status;
        //alert(Current_command_status);
        if (Current_command_status ==="S") {
            obj.GC = 0;
            obj.SP = 0;
        }
        var command_message = JSON.stringify(obj);
        send_command_message_to_robot(command_message, ctrl, ctrl_current_status);
        if (Current_turn_status === null || Current_turn_status === '') Current_turn_status = 0;
        if (Current_motor_speed_status === null || Current_motor_speed_status === '') Current_motor_speed_status = 0;
        if (Current_grass_cutter_status === null || Current_grass_cutter_status === '') Current_grass_cutter_status = 0;
        if (Current_sprayer_status === null || Current_sprayer_status === '') Current_sprayer_status = 0;
        // *** Save command message in database
        //Temp comment 20240629 const response = await Axios("/PlantRobotOperation/addcmd?pid=" + PlantID + "&uid=" + UserID +"&rid=" + RobotID + "&cmd=" + Current_command_status + "&tun=" + Current_turn_status + "&spd=" + Current_motor_speed_status + "&gc=" + Current_grass_cutter_status + "&sp=" + Current_sprayer_status + "&cb=0&mb=0&cid=1");
        //const response = await Axios("https://bhuhit/api/PlantRobotOperation/addcmd?pid=" + PlantID + "&uid=1&rid=" + RobotID + "&cmd=" + Current_command_status + "&tun=" + Current_turn_status + "&spd=" + Current_motor_speed_status + "&gc=" + Current_grass_cutter_status + "&sp=" + Current_sprayer_status + "&cb=0&mb=0&cid=1");
    }
};

//*** Send MQTT message to robots ***//
function send_command_message_to_robot(command_msg, control, ctrl_current_value) {
    try {
        mqtt_client.publish(MQTT_Subscribe_Topic, command_msg)
        console.log(command_msg)
    }
    catch (e) {
        console.log(e.message);
    }
}

//*** Set stop operation ***//
export const Stop_Operation = () => {
    mqtt_client.unsubscribe("zylv4u8fun1", function (error) {
        if (error) {
            console.log(error)
        } else {
            console.log('Unsubscribed Toipic zylv4u8fun1')
            mqtt_client.end()
            Set_Variables_Status();
        }
    })
    mqtt_client.unsubscribe("e1ypuwron21", function (error) {
        if (error) {
            console.log(error)
        } else {
            console.log('Unsubscribed Toipic e1ypuwron21')
            mqtt_client.end()
            Set_Variables_Status();
        }
    })
    Set_Variables_Status();
    mqtt_client.end()
};

//*** Set global variables to null or false
function Set_Variables_Status() {
    BroadcastingStatus = false;
    CameraCaptureStatus = false;
    Current_command_status = null;
    Current_turn_status = null;
    Current_motor_speed_status = null;
    Current_grass_cutter_status = null;
    Current_sprayer_status = null;
}
//*** Export capture_camera()
export const Capture_Camera = () => {
    //IPAddress = "https://www.youtube.com/embed/tgbNymZ7vqY"
    var ip = null
    ip = IPAddress //document.getElementById("ipcamera").setAttribute("src", IPAddress);
   // alert(IPAddress + "Capture Camera");
    if (IPAddress === null) ip = "http://192.168.137.150:8000" //document.getElementById("ipcamera").setAttribute("src", "http://192.168.137.150:8000");
    //CameraCaptureStatus = true
    return ip
};
function update_operational_data_to_status_info(command_msg) {
    Current_data = command_msg;
}
export const export_command_data_to_status_info = () => {
    return Current_data;
};

export function PlantRobotOperation() {
    const [robotState, setRobotState] = useState();
    const [toggleCam, setToggleCam] = useState(false);
    const [webcamButtonState, setWebcamButtonState] = useState(false);
    const [commandStatus, setCommandStatus] = useState([])
    const [data, setData] = useState([])
    const [searchTerm, setSearchTerm] = useState("");
    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };
    const filteredData = data.filter((row) =>
        Object.values(row).some(
            (value) => typeof value === "string" && value.includes(searchTerm)
        )
    );
    function handleWebcamButtonClick() {
        setWebcamButtonState(!webcamButtonState);
        CameraCaptureStatus = !webcamButtonState;
    }
    function handleRobotStateChange(newState) {
        if (!newState) {
            setToggleCam(false);
            setWebcamButtonState(!webcamButtonState);
        }
        setRobotState(newState);
    }
    useEffect(() => {
        fetchData();
    }, [])
    useEffect(() => {
        console.log(data)
    }, [data])

    //*** setInterval(fetchData, 1000);
    //*** Fetching robot operation data.
    const fetchData = async () => { 
        //*** Get query string PlantID and RobotID (pid, rid).
        RobotID = sessionStorage.getItem("robotID");
        PlantID = sessionStorage.getItem("user_plantid");
        UserID = sessionStorage.getItem("user_id");
        //alert(RobotID);
        //alert(PlantID);
        //*** Fetch robot operation data from API.
        //const response = await Axios("/PlantRobotsOperation?pid=" + PlantID+"&rid="+RobotID+"&rt=singlerobot_allcurrentcmd");
         //Temp comment 20240629 const response = await Axios("/PlantRobotsOperation?pid=" + PlantID+"&rid="+RobotID+"&rt=singlerobot_allcurrentcmd");
        //*** Set robot operation data.
        // //Temp comment 20240629 setData(response.data);
        opentab('Operation', 'btnOperation', '#fff')
    }
   
    //const GetCommandStatus = async () => {
    //    var dvCommandStatus = document.getElementById("idcommandStatus");
    //    if (dvCommandStatus.style.display == "block") {
    //        dvCommandStatus.style.display = "none";
    //    }
    //    else if (dvCommandStatus.style.display == "none") {
    //        fetchData();
    //        dvCommandStatus.style.display = "block";
    //    }
    //}
    function opentab(pageName, elmnt, color) {
        var i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("tabcontent");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].style.display = "none";
        }
        tablinks = document.getElementsByClassName("tablink");
        for (i = 0; i < tablinks.length; i++) {
            tablinks[i].style.backgroundColor = "";
        }
        document.getElementById(pageName).style.display = "block";
        document.getElementById(elmnt).style.backgroundColor = color;
    
    }
        return (
            <>
                <p></p>
                <button className="tablink" id="btnOperation" onClick={() => opentab('Operation', 'btnOperation', '#fff')} >Operation</button>
                <button className="tablink" onClick={() => opentab('Map', 'btnMap', '#fff')} id="btnMap">Map</button>
                <button className="tablink" onClick={() => opentab('OperationStatus', 'btnOperationStatus', '#fff')} id="btnOperationStatus">Status</button>
                <p></p>
                <div id="Operation" className="tabcontent">
                    {/*<h3 style={{ marginTop:"-2%", color:"#000" }}>Robot Operation</h3>*/}
                    <div className="divmain">
                    <div className="robot-components">
                        <div className="row robot-row">
                            <div className="col-lg-3">
                                <div className="row-item joystick-container1 label-name" disabled={!webcamButtonState}>
                                    <div className="joystickContainer1 label-name">
                                        <Joystick />
                                    </div>
                                </div>
                                <div className="row-item label-name">
                                    Robot's Direction Controller
                                </div>
                                <div className="row-item speed-container">
                                    <button className="btn bg-transparent" disabled={!webcamButtonState} onClick={() => Create_mqtt_command_message("Speed", 180)}>
                                        <img className="imgSpeed" src={slow} />
                                    </button>
                                    <button className="btn bg-transparent" disabled={!webcamButtonState} onClick={() => Create_mqtt_command_message("Speed", 210)}>
                                        <img className="imgSpeed" src={medium} />
                                    </button>
                                    <button className="btn bg-transparent" disabled={!webcamButtonState} onClick={() => Create_mqtt_command_message("Speed", 250)}>
                                        <img className="imgSpeed" src={fast} />
                                    </button>
                                </div>
                                <div className="row-item label-name" >Robot's Speed Controller</div>
                                <div className="row-item speed-container">
                                    <button
                                        className="btn speed-container label-name"
                                        onClick={() => {
                                            setToggleCam(!toggleCam);
                                            handleWebcamButtonClick();
                                        }}
                                        disabled={!robotState}
                                        id="webcam"
                                    >
                                        <img className="imgButton" src={videoCam} />
                                    </button>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <p></p>
                                <div className="videoContainer">
                                   <WebcamRenderer toggle={toggleCam}></WebcamRenderer>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                {/* <DateTime></DateTime>*/}
                                {/*<div className="row panel-row">*/}
                                {/*    <div className="col-sm-6">*/}
                                {/*        <BatteryGauge size={80} value={60} batteryMeter={{ fill: "lime" }} />*/}
                                {/*    </div>*/}
                                {/*    <div className="col-sm-6">*/}
                                {/*        <BatteryGauge style={customStyles} size={80} value={80} />*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                <ControlPanel
                                    onRobotStateChange={handleRobotStateChange}
                                    webcamButtonState={webcamButtonState}
                                >
                                    </ControlPanel>
                            </div>
                            {/*<div className="col-lg-1">*/}
                            {/*  <Menu Title="Menu" />*/}
                            {/*</div>*/}
                        </div>
                        
                    </div>
                    </div>
                </div>
                <div id="Map" className="tabcontent">
                    <p><RobotLocation></RobotLocation></p>
                </div>
                <div id="OperationStatus" className="tabcontent">
                    <h3 style={{ marginTop: "-2%", color:"#000" }} >Robot Operation Status</h3>
                        <div id="idcommandStatus" style={{ display: 'block' }}>
                            <DataTable
                                columns={columns}
                                data={data}
                                customStyles={customStyles}
                                highlightOnHover={true}
                                sort={true}
                                pagination={true}
                                search={true}
                            />
                        </div>
                </div>
            </>
        );
    }
