//*** Import React Library
import axios from "axios";
import { Button } from "bootstrap";
import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
export const ChartSprayingByHours = () => {
    const [chartData, setChartData] = useState({});
    const [data, setData] = useState([]);
    const areaCovered = [];
    const workingHours = [];
    const robotName = [];
    const GetSprayingByHours = async () => {
        var plant_id = sessionStorage.getItem("user_plantid");
        //let response = await axios("/SprayingByHour?pid=" + plant_id + "&ot=Spraying");
        let response = await axios("/SprayingByHour?pid=" + plant_id + "&ot=Spraying");
        setData(response.data);
        data.map((user) => {
            areaCovered.push(user.areaCovered);
            workingHours.push(user.workingHours);
        });
        setChartData({
            labels: workingHours,
            backgroundColor: ['rgba(75, 192, 192, 0.2)'],
            backgroundColor: ['rgba(153, 102, 255, 0.2)'],
            backgroundColor: ['rgba(255, 159, 64, 0.2)'],
            datasets: [
                {
                    fill: true,
                    label: "Area Covarage in Meter (m) By Current Date",
                    data: areaCovered, //[0, 10, 5, 2, 20, 30, 45],
                    backgroundColor: ["rgba(255, 206, 86, 0.2)"], //"lemonyellow", //"#5ADEFF",
                    borderColor: ["rgba(255, 99, 132, 1)"],
                    borderWidth: 1
                }
            ]
        });

    };
    useEffect(() => {
        GetSprayingByHours();
    }, []);
    useEffect(() => {
        console.log(data)
    }, [data])
    GetSprayingByHours();
    return (
        <div className="App123">
            <p></p>
            <h3 style={{ marginLeft: "3%" }}>Current Spraying By Hours</h3>
            <p></p>
            <p></p>
            {data.map((user) => (
                (user.id === 0 ? (
                    <div key={user.id}>
                        <Line data={chartData} />
                    </div>
                ) : (<></>))
            ))}
        </div>
    );
};