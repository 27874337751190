//*** import react library  ***//
import React, { useEffect, useState, Component } from "react";
//import { useNavigate } from "react-router-dom";
import axios from "axios";
import "../../Assets/Styles/UserLogin.css";
import 'font-awesome/css/font-awesome.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
//import Sidebar  from "../Shared/Sidebar";
//*** Export login component ***//
export const Login = () => {
    //*** Use state of array and variables using react useState();
   // const navigate = useNavigate();
    const [errorMessages, setErrorMessages] = useState({});
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [token, setToken] = useState("");
    const [user, setUser] = useState([]);
    const [loginStatus, setLoginStatus] = useState([]);
    const [passwordType, setPasswordType] = useState("password");
    const [passwordInput, setPasswordInput] = useState("");
    const [data, setData] = useState([]);
    const handlePasswordChange = (evnt) => {
        setPasswordInput(evnt.target.value);
    }
    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }

    //*** Regular expression for email ID ***//
    const passwordRegex =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    const errors = {
        username: "invalid username",
        password: "invalid password",
    };
    //*** Submit login page ***//
    const handleSubmit = async (event) => {
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("username");
        sessionStorage.removeItem("user_plantid");
        sessionStorage.removeItem("user_emailid");
        sessionStorage.removeItem("user_id");
        sessionStorage.removeItem("role_id");
        sessionStorage.removeItem("first_name");
        var user_password = document.getElementById("password").value;
        var user_name = document.getElementById("username").value;
       // alert(user_password)
        event.preventDefault();
        if (!passwordRegex.test(user_password)) {
            setErrorMessages({
                name: "password",
                message:
                    "Password must contain at least 8 characters including uppercase and lowercase letters and numbers.",
            });
            return;
        } else {
            CheckUserLogin(user_name, user_password);
        }
    };

    //*** Check user name and password ***//
    const CheckUserLogin = async (_user_name, _user_password) => {
        try {
            let plant_id = null;
            let role_id = null;
            let response = await axios.get("/Login?un=" + _user_name + "&up=" + _user_password + "")
            //let response = await axios.get("http://bhuhit.com/api/Login?un=" + _user_name + "&up=" + _user_password + "")
            setData(response.data);
            data.map((user) => {
                if (user.userEmailID !== _user_name) {
                    //*** Invalid User Name
                    setErrorMessages({ name: "invalid", message: "Invalid user name." });
                    return;
                }
                else if (user.userPassword !== _user_password) {
                    //*** Invalid password
                    setErrorMessages({ name: "invalid", message: "Invalid password." });
                    return;
                }
                else if (user.userPassword !== _user_password && user.userEmailID !== _user_name) {
                    //*** Invalid user name and password
                    setErrorMessages({ name: "invalid", message: "Invalid user name and password." });
                    return;
                }
                else {
                    plant_id = user.userPlantID;
                    role_id = user.userRoleID;
                    setIsSubmitted(true);
                    sessionStorage.setItem("user_plantid", user.userPlantID);
                    sessionStorage.setItem("user_emailid", user.userEmailID);
                    sessionStorage.setItem("user_id", user.userID);
                    sessionStorage.setItem("role_id", user.userRoleID);
                    sessionStorage.setItem("first_name", user.userFirstName);
                    sessionStorage.setItem("token", user.userEmailID + "_" + user.userPassword);
                    sessionStorage.setItem("username", user.userEmailID);
                }
            });
            if (role_id == null) {
                var buttonSubmit = document.getElementById("btnSubmit");
                buttonSubmit.click();
            }
            if (role_id != null && plant_id != null) {
                //Sidebar.showSidebar();
                //Sidebar.axios
                var baseURL = "/#/RobotsOperation?pid=" + plant_id + "";
                //var baseURL = "http://bhuhit.com/#/RobotsOperation?pid=" + plant_id + "";
                window.location.href = baseURL;
            }
        } catch (error) {
            console.log(error);
        }
    }

    //*** Render Error Messages ***//
    const renderErrorMessage = (name) =>
        name === errorMessages.name && (
            <div className="errorContainer errorMessage">{errorMessages.message}</div>
        );
    return (
        <>
            <div className="container-ul">
                <div className="form-box">
                    <div className="header-form">
                        <h4 className="text-primary text-center"><i className="fa fa-user-circle-o teal-color" style={{ fontSize: "100px" }}></i></h4>
                        <div className="image">
                        </div>
                    </div>
                    <div className="body-form">
                      
                        {/*{!isSubmitted ? (*/}
                      {/*  <form onSubmit={handleSubmit}>*/}
                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text"><i class="fa fa-user" style={{ fontSize: "24px" }}></i></span>
                                </div>
                                    <input type="text" className="form-control" placeholder="Enter User Name" name="username" id="username" onChange={(e) => setUsername(e.target.value)} required />
                                {renderErrorMessage("username")}
                            </div>
                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text"><i class="fa fa-lock" style={{ fontSize: "24px" }}></i></span>
                                </div>
                                <input type={passwordType} onChange={ handlePasswordChange } value={passwordInput} name="password" id="password" className="form-control" placeholder="Enter Password" />
                                {renderErrorMessage("password")}
                                <div className="input-group-btn">
                                    <button type="button" className="btn btn-light" onClick={togglePassword}>
                                    {passwordType === "password" ? <span><i className="fa fa-eye" style={{ fontSize: '22px', color: '#99cc33' }}></i></span> /*<i className="fa fa-eye"></i>*/ : <span><i className="fa fa-eye-slash" style={{ fontSize: '22px', color: '#99cc33' }}></i></span>}
                                    </button>
                            </div>
                            </div>
                        <p className="errorMessage">{renderErrorMessage("invalid")}</p>
                            <button type="submit" id="btnSubmit" onClick={handleSubmit} className="btn btn-secondary btn-block" style={{backgroundColor:"#95c92c", fontWeight:"400", fontSize:"120%"}}>LOGIN</button>
                            <div className="message">
                        </div>
                                {user && user.length > 0 && user.map((userObj, index) => (
                                    <li key={userObj.id}>{userObj.ok}</li>
                                ))}
                        {/*</form>*/}
                        {/*) : (*/}
                        {/*    <></>*/}
                        {/*)*/}
                        {/*}*/}
                    </div>
                </div>
            </div>
        </>
    );
};
export default Login;