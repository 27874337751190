//*** Import React Library
import axios from "axios";
import { Button } from "bootstrap";
import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
export const ChartSprayingByMonths = () => {
    const [chartData, setChartData] = useState({});
    const [data, setData] = useState([]);
    const areaCovered = [];
    const workingMonths = [];
    const robotName = [];
    const GetSprayingByMonths = async () => {
        var plant_id = sessionStorage.getItem("user_plantid");
        //let response = await axios("/SprayingByMonth?pid=" + plant_id + "&ot=Spraying");
        let response = await axios("/SprayingByMonth?pid=" + plant_id + "&ot=Spraying");
        setData(response.data);
        data.map((user) => {
            areaCovered.push(user.areaCovered);
            workingMonths.push(user.workingMonths);
        });
        setChartData({
            labels: workingMonths,
            datasets: [
                {
                    fill: true,
                    label: "Area Covarage in Meter (m) By Current Date",
                    data: areaCovered, //[0, 10, 5, 2, 20, 30, 45],
                    backgroundColor: ['rgba(54, 162, 235, 0.2)'], 
                    borderColor: ["rgba(255, 99, 132, 1)"], 
                    borderWidth:1
                }
            ]
        });
    };
    useEffect(() => {
        GetSprayingByMonths();
    }, []);
    useEffect(() => {
        console.log(data)
    }, [data])
    GetSprayingByMonths();
    return (
        <div>
            <p></p>
            <h3 style={{ marginLeft: "3%" }}>Current Year Spraying By Months</h3>
            <p></p>
            <p></p>
            {data.map((user) => (
                (user.id === 0 ? (
                    <div key={user.id}>
                        <Line data={chartData} />
                    </div>
                ) : (<></>))
            ))}
        </div>
    );
};