import { Capture_Camera } from "./PlantRobotOperation";
import "../../Assets/Styles/robot.css";
import Iframe from "react-iframe";
export const WebcamRenderer = (props) => {
    var videoPath = Capture_Camera()
    //var videoPath = "https://www.youtube.com/watch?v=EKbw0091ymM";
    return (
        <>
            { props.toggle ? 
                <div>
                    {/*<video src={videoPath} width="100%" height="60%" controls>*/}
                    {/*</video>*/}
                    {/*<iframe width="100%" height="400px" src={videoPath} loading="lazy" allow="microphone;camera"></iframe>*/}
                    <Iframe url={videoPath} className='iframe' loading="lazy" allow="microphone;camera" ></Iframe>
                </div> : <></>
            }
        </>
       );
}
export default WebcamRenderer