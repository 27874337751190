import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import { Layout } from './components/Shared/Layout';
import './custom.css';
import "./App.css";
export default class App extends Component {
    static displayName = App.name;

    render() {
        return (
            <Layout>
                <Routes>
                    {AppRoutes.map((route, index) => {
                        const { element, ...rest } = route;
                        return <Route key={index} {...rest} element={element} />;
                    })}
                </Routes>
            </Layout>
        );
    }
}




//import React, { Component } from 'react';
//import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
//import AppRoutes from './AppRoutes';
//import { Layout } from './components/Shared/Layout';
//import './custom.css';
//import "./App.css";
//import UserLogin  from './components/Account/UserLogin';
//import { RobotsOperation } from "./components/RobotOperation/RobotsOperation";
//import RobotLocation from "./components/RobotOperation/RobotLocation";
///*import { Login } from './components/Account/UserLogin';*/
//export default class App extends Component {
//    static displayName = App.name;
//  render() {
//      return (
//        <Layout>
//        <Routes>
//          {
//              AppRoutes.map((route, index) => {
//                alert(route.path);
//                const { element, ...rest } = route;
//                          //alert(...rest);
//                          //alert(element +"hi");
//              return <Route key={index} {...rest} element={element} />;
//          })}
//        </Routes>

//          {/*<Routes>*/}
//          {/*        <Route exact  path="/" key="1" element={<UserLogin/>} />*/}
//          {/*        <Route exact  path="/" key="2"  element={<RobotsOperation/>} />*/}
//          {/*        <Route index exact path="/" key="3" element={<RobotLocation/>} />*/}
//          {/*        */}{/* <Route path="*" element={<NoPage />} />*/}
//          {/*</Routes>*/}
//        </Layout> 
//    );
//  }
//}
