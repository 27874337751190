//*** Import React Library
import axios from "axios";
import { Button } from "bootstrap";
import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";

//*** Export current chart of grass cutting by hours 
export const ChartGrassCuttingByHours = () => {
    const [chartData, setChartData] = useState({});
    const [data, setData] = useState([]);
    const areaCovered = [];
    const workingMonth = [];
    const robotName = [];

    //*** Fetch data from API
    const GetGrassCuttingByHours = async () => {
        var plant_id = sessionStorage.getItem("user_plantid");
        //let response = await axios("/GrassCuttingByHour?pid=" + plant_id + "&ot=GrassCutting");
        let response = await axios("/GrassCuttingByHour?pid=" + plant_id + "&ot=GrassCutting");
        setData(response.data);
        data.map((user) => {
            areaCovered.push(user.areaCovered);
            workingMonth.push(user.workingHours);
        });

        //*** Set Chart Data
        setChartData({
            labels: workingMonth,
            datasets: [
                {
                    fill: true,
                    label: "Area Coverage in Meter (m) By Hours",
                    data: areaCovered, //[0, 10, 5, 2, 20, 30, 45],
                    backgroundColor: ['rgba(54, 162, 235, 0.2)'],
                    borderColor: ["rgba(255, 99, 132, 1)"], 
                    borderWidth: 1
                }
            ]
        });

    };

    //*** Use React hooks - if occur any changes on page then useffect() function will run auto
    useEffect(() => {
        GetGrassCuttingByHours();
    }, []);
    useEffect(() => {
        console.log(data)
    }, [data])
    GetGrassCuttingByHours();
    // *** Return html code 
    return (
        <div>
            <p></p>
            <h3 style={{ marginLeft: "2%" }}>Current Grass Cutting By Hours</h3>
            <p></p>
            <p></p>
            {data.map((user) => (
                (user.id === 0 ? (
                    <div key={user.id}>
                        <Line data={chartData} />
                    </div>
                ) : (<></>))
            ))}
        </div>
    );
};