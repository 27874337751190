import React, { useState, useEffect } from "react";
import "../../Assets/Styles/ControlPanel.css";
import RobotOperations from "./PlantRobotOperation";
import { Client_subscribe_to_mqtt_topic } from "./PlantRobotOperation";
import { Create_broadcast_frames } from "./PlantRobotOperation";
import { Create_mqtt_command_message } from "./PlantRobotOperation";
import { Stop_Operation } from "./PlantRobotOperation";
import axios from "axios";

//*** Step-2 ***//
//*** Subscribe to a topic ***//
const Start = () => {
    Client_subscribe_to_mqtt_topic() 
  
};

//***Stop Robot Operation
const Stop = () => {
    Create_mqtt_command_message('StopOperation', 1)
    console.log('Stop Operation')
};

//*** Send command to sprayer on
const Sprayer_on = () => {
    Create_mqtt_command_message("Sprayer", 1)
    console.log('Sprayer on')
};

//*** Send command to sprayer off
const Sprayer_off = () => {
    Create_mqtt_command_message("Sprayer", 0)
    console.log('Sprayer off')
};

const GrassCutter_status = (id) => {
    //***Publish a message to a topic ***//
    Create_mqtt_command_message("GrassCutter", id)
    console.log('GrassCutter on')
};

//*** Send command to grass cutter off
const GrassCutter_off = () => {
    Create_mqtt_command_message("GrassCutter", 0)
    console.log('GrassCutter off')
};
const Sowing_on = () => {
    Create_mqtt_command_message("Sowing", 1)
    console.log('Sowing on')
};
const Sowing_off = () => {
    Create_mqtt_command_message("Sowing", 0)
    console.log('Sowing off')
};
const Harvesting_on = () => {
    Create_mqtt_command_message("Harvesting", 1)
    console.log("Harvesting on");
};
const Harvesting_off = () => {
    Create_mqtt_command_message("Harvesting", 0)
    console.log("Harvesting off");
};
//*** Export  Control panel components
export function ControlPanel(props) {
  const [activeButtons, setActiveButtons] = useState({});
  const [robotState, setRobotState] = useState();
    const [activeControl, setActiveControl] = useState();
   // const [superVillains, setSuperVillains] = useState([]);
    useEffect(() => {
  }, [activeButtons]);

  const handleClick = (id) => {
    let value = 0;
    if (activeButtons[id]) {
      setActiveButtons((prevButtons) => ({
        ...prevButtons,
        [id]: false,
      }));
      value = 0;
    } else {
      setActiveButtons((prevButtons) => ({
        ...prevButtons,
        [id]: true,
      }));
      value = 1;
    }
      switch (id) {
      case "start":
              console.log("Subscribed");
              Create_broadcast_frames();
              Client_subscribe_to_mqtt_topic();
        break;
      case "stop":
            console.log("Stop Operation");
            Stop_Operation();
        break;
      case "grassCutter":
            console.log("GrassCutter : " + value);
            Create_mqtt_command_message("GrassCutter", value);
            
        break;
      case "harvesting":
            console.log("Harvesting : " + value);
           // Create_mqtt_command_message("Harvesting", value);
        break;
      case "sprayer":
            console.log("Sprayer : " + value);
            Create_mqtt_command_message("Sprayer", value);
           
        break;
      case "sowing":
            console.log("Sowing : " + value);
            //Create_mqtt_command_message("Sowing", value);
        break;
      case "autos":
            console.log("Autos. : " + value);
            //Create_mqtt_command_message("Sprayer", value);
        break;
      case "cloud":
        console.log("Cloud : " + value);
        break;
      case "remote":
        console.log("Remote : " + value);
        break;
    }
  };
  function handleRobotStateChange(newState) {
    setRobotState(newState);
    props.onRobotStateChange(newState);
  }
  const handleStartStop = (id) => {
    if (id === "start") {
      setRobotState(true);
      handleRobotStateChange(true);
    } else {
      setRobotState(false);
      handleRobotStateChange(false);
    }
     handleClick(id);
  };
  return (
    <>
          <div>
            {/* <div className="row panel-row">*/}
            {/*     <div className="col-sm-4">*/}
            {/*        <button className="btn panel-button">Autos.</button>*/}
            {/*     </div>*/}
            {/*      <div className="col-sm-4">*/}
            {/*        <button className="btn panel-button">Cloud</button>*/}
            {/*      </div>*/}
            {/*      <div className="col-sm-4">*/}
            {/*        <button className="btn panel-button">Remote</button>*/}
            {/*      </div>*/}
            {/*</div>*/}
            <div className="row panel-row">
                <div className="col-sm-12">
                      <table className="tblwidth">
                          <tr>
                              <td>
                                  <button
                                      className={
                                          activeButtons["start"]
                                              ? "btn panel-button active"
                                              : "btn panel-button"
                                      }
                                      id="start"
                                      onClick={() => handleStartStop("start")}
                                      disabled={props.webcamButtonState}
                                  >
                                      {/*disabled={robotState}*/}
                                      Start
                                  </button>
                              </td>
                              <td>
                                  <button
                                      className={
                                          activeButtons["start"]
                                              ? "btn panel-button"
                                              : "btn panel-button active"
                                      }
                                      onClick={() => handleStartStop("stop")}
                                      disabled={!props.webcamButtonState}
                                  >
                                      {/* disabled={!robotState}*/}
                                      Stop
                                  </button>
                              </td>
                          </tr>
                      </table>
           
          </div>
          {/*<div className="col-sm-6">*/}
          
          {/*</div>*/}
         
        </div>
        <div className="row panel-row">
                  <div className="col-sm-12">
                      <table className="tblwidth">
                          <td>
                              <button
                                  className={
                                      activeButtons["grassCutter"]
                                          ? "btn panel-button active"
                                          : "btn panel-button"
                                  }
                                  id="grassCutter"
                                  onClick={() => handleClick("grassCutter")}
                                  disabled={!props.webcamButtonState}
                              >
                                  Grass Cutter
                              </button>
                          </td>
                          <td>
                              <button
                                  className={
                                      activeButtons["sprayer"]
                                          ? "btn panel-button active"
                                          : "btn panel-button"
                                  }
                                  id="sprayer"
                                  onClick={() => handleClick("sprayer")}
                                  disabled={!props.webcamButtonState}
                              >
                                  Sprayer
                              </button>
                          </td>
                      </table>
         
          </div>
          {/*<div className="col-sm-6">*/}
           
          {/*</div>*/}
        </div>
        <div className="row panel-row">
          <div className="col-sm-12">
          <table className="tblwidth">
            <tr>
                <td>
                    <button
                      className={
                        activeButtons["sowing"]
                          ? "btn panel-button active"
                          : "btn panel-button"
                      }
                      id="sowing"
                      onClick={() => handleClick("sowing")}
                      disabled={!props.webcamButtonState}
                >
                  Sowing
                </button>
                </td>
                <td>
                    <button
                        className={
                            activeButtons["harvesting"]
                            ? "btn panel-button active"
                               : "btn panel-button"
                             }
                             id="harvesting"
                             onClick={() => handleClick("harvesting")}
                             disabled={!props.webcamButtonState}
                           >
                       Harvesting
                    </button>
                </td>
            </tr>
          </table>
          </div>
          {/*<div className="col-sm-6">*/}
          {/*</div>*/}
          {/*
                        <div className="col-sm-6"><button className="btn panel-button bg-transparent"><img className="imgButton" src={sowing} /></button></div>
                        <div className="col-sm-6"><button className="btn panel-button bg-transparent"><img className="imgButton" src={harvesting} /></button></div>
          */}
        </div>
      </div>
    </>
  );
}
